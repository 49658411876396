import { useEffect } from "react"

export default function HomePage() {
    useEffect(() => {
        window.location.href = "/bets"
    })
    return (
        <p>
            

        </p>
    )
}