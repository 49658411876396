import { Center, SimpleGrid, Spacer, VStack } from "@chakra-ui/react"
import { useEffect } from "react"

export default function RulesPage() {
    
    return (
        <>
        <Center style={{marginTop:'20px'}}>
            <VStack>
                <h1 style={{width:'950px', textAlign:'center', fontSize:64}}><span style={{fontSize:72}}>"</span>This whole.. college admissions thing isn't very fun<span style={{fontSize:72}}>"</span></h1>
                <h2 style={{color:'#494846'}}>- Hayden, Probably</h2>
            </VStack>

            
            
        </Center>

        

        <SimpleGrid gap="40px" columns={[2, 3]} style={{width:'70%', justifyContent:'center', margin:'auto'}}>
            <VStack style={{justifyContent:'start', margin:'auto', marginTop:'80px', width:'55%' }}>
                <h1 style={{color:'#494846', justifyContent:'start', margin:'auto'}}>Step 1. Research</h1>
                <p style={{color:'#494846', justifyContent:'start', margin:'auto'}}>Check out the stats for the various schools I'm applying to. Determine how likely you think I am to get Accepted, Waitlisted, or Rejected.</p>
            </VStack>
            <VStack style={{justifyContent:'start', textAlign:'start', margin:'auto', marginTop:'80px', width:'55%' }}>
                <h1 style={{color:'#494846', justifyContent:'start', margin:'auto'}}>Step 2. <h1>Make Bet</h1></h1>
                <p style={{color:'#494846', justifyContent:'start', margin:'auto'}}>Make a bet using your points (look in the top right). Bet on whether I'll get Accepted, Rejected, or Waitlisted from various institutions. Depending on the odds the payout could be greater or lesser. </p>
            </VStack>
            <VStack style={{justifyContent:'start', textAlign:'start', margin:'auto', marginTop:'80px', width:'55%' }}>
                <h1 style={{color:'#494846', justifyContent:'start', margin:'auto'}}>Step 3. Profit</h1>
                <p style={{color:'#494846', justifyContent:'start', margin:'auto'}}>When the decision comes out from the college, those who bet correctly will receive points according to the odds. Try to get the most points! </p>
            </VStack>
            
        </SimpleGrid>
        </>
    )
}