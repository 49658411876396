import { useEffect, useState } from 'react';
import {User} from "./models/user"
import * as UserApi from "./network/user_api"
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LoginPage from "./pages/LoginPage";
import HomePage from './pages/HomePage';
import NotFoundPage from './pages/NotFoundPage';
import CollegesPage from './pages/CollegesPage';
import { StatRoot, StatLabel, StatValueText, StatHelpText, StatUpIndicator, StatDownIndicator, StatValueUnit, HStack, Icon, Center } from '@chakra-ui/react';
import Logo from './components/Logo';
import { Avatar } from '@chakra-ui/avatar';
import SignupPage from './pages/SignupPage';
import LeaderboardPage from './pages/LeaderboardPage';
import RulesPage from './pages/RulesPage';

function App() {
  // const [get, set]
  const [loggedInUser, setLoggedInUser] = useState<User|null>(null);

  const [loadingUser, setLoadingUser] = useState(true);
  const [userPoints, setUserPoints] = useState(0);
  
  useEffect(() => {
    async function fetchLoggedInUser() {
      try {
        setLoadingUser(true);
        const user = await UserApi.getLoggedInUser();
        setLoggedInUser(user);  
        setUserPoints(user.points);
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingUser(false);
      }
    }

    fetchLoggedInUser();
  }, []);
  
  

  return (
    <BrowserRouter>
        <div>

        {
        /*loggedInUser && !(window.location.pathname === "/") && loggedInUser.organization != "NULLL" && <Sidebar
        loggedInUser={loggedInUser}
        />
        */
        }

            {!window.location.href.includes("login") && !window.location.href.includes("signup") && <div style={{position:'fixed', top:0, width:'100%', backgroundColor:'#494846', height:'62px', paddingBottom:'6px', zIndex:1}}>
              
              {false && <div style={{position:'fixed', display:'flex', textAlign:'center', paddingTop:'10px', justifyContent:'center', margin:'auto'}}>
                  <div style={{ marginBottom:'-45px', marginTop:'0px', marginLeft:'10px', cursor:'pointer'}} onClick={() => {window.location.href = "/"}}>
                      <Logo color='#FFFFFF' height='3rem' width='3rem'/>
                  </div>
                  
                </div>}

                <div style={{display:'flex', paddingTop:'10px', justifySelf:'center', justifyContent:'center', margin:'auto', textAlign:'center'}}>
                  <div>
                  {window.location.href.includes("/rules") ?
                        <b>
                        <a className='NavItem' href='/rules'>
                          
                          Rules
                        </a>
                        </b>
                        :
                        <a className='NavItem' href='/rules'>
                          
                          Rules
                        </a>}
                  </div>
                  
                  <div style={{paddingLeft:'9px'}}>
                      {window.location.href.includes("/leaderboard") ?
                        <b>
                        <a className='NavItem' href='/leaderboard'>
                          
                          Leaderboard
                        </a>
                        </b>
                        :
                        <a className='NavItem' href='/leaderboard'>
                          
                          Leaderboard
                        </a>}
                        </div>
                        <div style={{paddingLeft:'9px'}}>
                      {window.location.href.includes("/bets") ?
                        <b>
                        <a className='NavItem' href='/bets'>
                          
                          Bets
                        </a>
                        </b>
                        :
                        <a className='NavItem' href='/bets'>
                          
                          Bets
                        </a>}

                        
                        </div>

                        <div style={{position:'fixed', display:'flex', justifyContent:'flex-end', textWrap:'nowrap', paddingTop:'10px', right:'20px'}}>
                          <p style={{fontWeight:600}}>{userPoints.toLocaleString()} Points</p>
                          <p style={{paddingLeft:'30px'}}>{loggedInUser?.username}</p>
                        </div>
                </div>
            </div>}


            <div style={{paddingTop:'3%', paddingBottom:'45px'}}>
            <Routes>
              
              <Route 
              path="/login"
              element={<LoginPage changeLoggedInUser={function (user: User): void {
                setLoggedInUser(user);
              } }/>}
              />

              <Route 
              path="/signup"
              element={<SignupPage changeLoggedInUser={function (user: User): void {
                setLoggedInUser(user);
              } }/>}
              />

              <Route 
              path="/"
              element={<HomePage/>}
              />

              <Route 
              path="/bets"
              element={<CollegesPage setPoints={(amount) => {
                if (loggedInUser){
                  loggedInUser.points = amount;


                }
              }} loggedInUser={loggedInUser}/>}
              />

              <Route 
                path='/leaderboard'
                element={<LeaderboardPage loggedInUser={loggedInUser}/>}
              />

              <Route 
                path='/rules'
                element={<RulesPage/>}
              />

              <Route
              path="/*"
              element={<NotFoundPage/>}
              />

            </Routes>
            </div>

            <div style={{width:'100%', height:'fit-content', backgroundColor:'#494846', position:'absolute', bottom:'0px', marginTop:'60px'}}>
              <Center>
                <p style={{padding:'5px', paddingBottom:'0px',}}>Developed by <a style={{color:'white'}} rel="noreferrer" target="_blank" href="https://www.haydenkarp.com">Hayden Karp</a> for fun</p>
              </Center>
              
            </div>
        </div>
      
      
      </BrowserRouter>
      
  );

  /*
  <BrowserRouter>
        <div className="App">
        
          <TrainingNavBar 
          loggedInUser={loggedInUser}
          onLoginClicked={() => {setLoginVisibile(true)}} 
          onSignUpClicked={() => {setSignUpVisibile(true)}} 
          onLogoutSuccessful={() => {setLoggedInUser(null)}} 
          />

          <Container>
            <Routes>
              <Route 
              path="/quizzes"
              element={<QuizzesMenu loggedInUser={loggedInUser}/>}
              />

              <Route 
              path="/"
              element={<HomePage/>}
              />

              <Route 
              path="/leaderboard"
              element={<Leaderboardpage/>}
              />

              {/*
              <Route 
              path="/question"
              element={<QuestionPage loggedInUser={loggedInUser}/>}
              />
              //}
              

              <Route 
              path="/dashboard"
              element={<DashboardPage loggedInUser={loggedInUser}/>}
              />

              <Route
              path="/*"
              element={<NotFoundPage/>}
              />

            </Routes>
          </Container>

          { signUpVisible &&
            <SignUpModal 
              onDismiss={() => {setSignUpVisibile(false)}}
              onSignUpSuccessful={(user) => {setLoggedInUser(user); setSignUpVisibile(false);}}
            />
          }

          { loginVisibile &&
            <LoginModal 
              onDismiss={() => {setLoginVisibile(false)}}
              onLoginSuccessful={(user) => {setLoggedInUser(user); setLoginVisibile(false);}}
            />
          }
        
        </div>
      </BrowserRouter>
    */
}

export default App;
