import {User} from "../models/user";

async function fetchData(input: RequestInfo, init?: RequestInit) {
    // console.log("Fetching Request: ", input, init);
    
    const response = await fetch(input, init);

    if (response.ok){
        return response;
    }else{
        const errorBody = await response.json();
        const errorMessage = errorBody.error;
        throw new Error(errorMessage);
    }
}

export async function getLoggedInUser(): Promise<User> {
    const response = await fetchData("/api/users", {method:"GET"});
    return response.json();
}

export async function getColleges(): Promise<any[]> {
    try {
        const response = await fetchData("/api/colleges", {method:"GET"});
        const a = await response.json();

        return a.colleges;
    } catch (error) {
        throw error;
    }
    
}


interface SignUpBody {
    username?: string,
    email?: string,
    password?: string,
}

export async function signupUser(props: SignUpBody): Promise<User> {
    const response = await fetchData("/api/users/signup", {method:"POST", headers : {
        "Content-Type": "application/json"
    }, body : JSON.stringify(props)});
    const u = await response.json();

    return u.user;
}

interface LoginBody {
    email?: string,
    password?: string,
}

export async function loginUser(props: LoginBody): Promise<User> {
    const response = await fetchData("/api/users/login", {method:"POST", headers : {
        "Content-Type": "application/json"
    }, body : JSON.stringify(props)});
    return response.json();
}

interface MakeBetBody {
    collegeName: string,
    amount: number,
    expectedOutcome: number
}

export async function makeBet(props: MakeBetBody) {
    const response = await fetchData("/api/colleges/makeBet", {method:"POST", headers : {
        "Content-Type": "application/json"
    }, body : JSON.stringify(props)});
    return response.json();
}

interface GetBetLeaderboardBody {
    onlyFamily: boolean,
}

export async function getLeaderboard(props: GetBetLeaderboardBody) {
    const response = await fetchData("/api/colleges/getBetLeaderboard", {method:"POST", headers : {
        "Content-Type": "application/json"
    }, body : JSON.stringify(props)});
    return response.json();
}