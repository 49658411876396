import { loginUser } from "../network/user_api";
import { Status } from "../components/ui/status";
import { Button, Input } from "@chakra-ui/react";
import { useState } from "react";
import { User } from "@/models/user";

interface SignupPageProps {
    changeLoggedInUser : (user:User) => void
}

export default function LoginPage(props: SignupPageProps) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");

    async function tryLogin() {
        setLoading(true);

        try {
            const res = await loginUser({
                email: email,
                password: password
            });

            props.changeLoggedInUser(res);

            window.location.href = "/bets"
        } catch (error:any) {
            setError(error.message.includes("Unexpected token") ? "Server down, please try again in a few minutes" : error.message);
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
        <h1 style={{textAlign:'center'}}>Login</h1>
        <p style={{color:'#494846', textAlign:'center', marginBottom:'6rem'}}>Don't have an account? <span style={{cursor:'pointer'}} onClick={() => {window.location.href = "/signup"}}><u>Sign up here</u></span></p>
        <div style={{display:'block', justifyContent:'center', verticalAlign:'middle', margin:'auto', width:'500px', height:'fit-content', paddingBottom:'20px', borderColor:'#494846', borderRadius:'20px', borderWidth:'3px', backgroundColor:'#ECECE1'}}>
            <div style={{display:'block', paddingTop:'20px', width:'75%', justifyContent:'center', margin:'auto'}}>
                <p style={{color:'#494846', fontWeight:600, marginBottom:'4px'}}>Email</p>
                <Input onChange={(e) => {
                    setEmail(e.target.value);
                    
                }} type="email" style={{borderColor:'#494846', marginLeft:'2px', marginRight:'2px', fontFamily:'Lora'}} placeholder="Hayden@haydenmail.com" />
                <p style={{color:'#9A2C1E', fontWeight:600, marginBottom:'4px'}}>{emailError}</p>
            </div>
            
            <div style={{display:'block', paddingTop:'40px', width:'75%', justifyContent:'center', margin:'auto'}}>
                <p style={{color:'#494846', fontWeight:600, marginBottom:'4px'}}>Password</p>
                <Input onChange={(e) => {
                    setPassword(e.target.value);
                    
                }} type="password" style={{borderColor:'#494846', marginLeft:'2px', marginRight:'2px', fontFamily:'Lora'}} />
                <p style={{color:'#9A2C1E', fontWeight:600, marginBottom:'4px'}}>{passwordError}</p>
            </div>

            <div style={{display:'flex', paddingTop:'40px', width:'75%', justifyContent:'center', textAlign:'center', margin:'auto'}}>
                <Button onClick={() => {
                      setPasswordError("")
                      setEmailError("")
                      setError("")
  
                      let a = true;
  
                      if (password.length < 3){
                          setPasswordError("Passwords must be at least 3 characters long");
                          a = false;
                      }
  
                      if (!email.match(new RegExp(/^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/gm))){
                          setEmailError("Please enter a valid email");
                          a = false;
                      }
  
                      if (a){
                          tryLogin();
                      }
                      
                }} style={{borderRadius:'7px', fontFamily:'Laro', fontWeight:600}}>Sign In</Button>
                
            </div>

            <div style={{display:'flex', paddingTop:'20px', width:'75%', justifyContent:'center', textAlign:'center', margin:'auto'}}>
                {error != "" && <Status value="error" style={{paddingLeft:'10px'}}>{error}</Status> }
            </div>

            
        </div>
        </>
    )
}