import { User } from "../models/user";
import { getLeaderboard } from "../network/user_api";
import { Center, Separator, Spinner, Table, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";

interface LeaderboardPageProps {
    loggedInUser: User | null
}

export default function LeaderboardPage({loggedInUser}: LeaderboardPageProps) {
    const [scores, setScores] = useState([]);
    const [loading, setLoading] = useState(true);

    async function grabLeaderboard() {
        setLoading(true);

        try {
            let u = ""
            if (loggedInUser){
                u = loggedInUser.username.toLowerCase();
            }else{
                u = "";
            }

            const r = await getLeaderboard({
                onlyFamily: u.includes("karp")
            });  

            setScores(r.scores);
        } catch (error) {
            console.log(error);
            
        } finally {
            setLoading(false);
        }
        
    }

    useEffect(() => {
        grabLeaderboard();
    }, [])

    return (
        <div style={{justifyContent:'center', margin:'auto', marginLeft:'2%'}}>
            <Center>
                <VStack>
                {loading && <Spinner style={{margin:'auto'}}/>}
                <Table.Root size={"lg"} borderRadius={20} style={{justifyContent:'center', width:'25%', margin:'auto', marginTop:'40px', borderRadius:'20px', height:'90px', fontWeight:600, backgroundColor:'#F3F2E6'}}>
                        <Table.Header>
                            <Table.Row style={{backgroundColor:'#F3F2E6', color:'#494846', fontFamily:'Lora'}}>
                            <Table.ColumnHeader style={{color:'#494846', fontWeight:600}}>Name</Table.ColumnHeader>
                            <Table.ColumnHeader style={{color:'#494846', fontWeight:600}}>Total Bet</Table.ColumnHeader>
                            <Table.ColumnHeader style={{color:'#494846', fontWeight:600}}>Total Won</Table.ColumnHeader>
                            </Table.Row>
                        </Table.Header>
                        
                        <Table.Body>
                            {scores.map((scoreObject:any, index:number) => (
                                <Table.Row style={{backgroundColor:'#F3F2E6', color:'#494846', fontFamily:'Lora'}} key={index}>
                                    <Table.Cell>{scoreObject.user.username}</Table.Cell>
                                    <Table.Cell>{scoreObject.totalBet}</Table.Cell>
                                    <Table.Cell>{scoreObject.totalWon}</Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                </Table.Root>
                </VStack>
            </Center>
        </div>
        
    )
}